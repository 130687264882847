const user = {
    state: {
        token: localStorage.getItem('token'),
        openId: '',
        accessToken: '',
        loginDialog: false,
        authType: 1,
        isFlutter: false
    },
    mutations: {
        CHANGE_TOKEN(state, token) {
            state.token = token
            localStorage.setItem("token", token)
        },
        CLEAR_TOKEN(state) {
            state.token = ''
            localStorage.clear()
        },
        SET_BIND_INFO(state, data) {
            state.openId = data.openId
            state.accessToken = data.accessToken
            console.log('state',state)
        },
        OPEN_DIALOG(state) {
            state.loginDialog = true
        },
        SET_AUTH_Type(state, authType) {
            state.authType = authType
        },
        CHANGE_FLUTTER(state, boolean) {
            state.isFlutter = boolean
        }
    },
    actions: {
    },
}


export default user
